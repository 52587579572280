<template>
	<div v-if="authed">
		<splitpanes horizontal class="default-theme" style="height:90vh">
			<pane min-size="10" size="35">
			<div style="max-height: 100%" class="overflow-y-auto">
				<v-data-table :headers="headers" :items="items" item-key="_id" v-model="selected"
					:options.sync="options" :server-items-length="totalItems"
					:loading="loading" loading-text="加载中..." show-select single-select>
				<template v-slot:top>
					<v-btn-toggle mandatory dense v-model="filter" @change="fetchData">
						<v-btn>全部待备</v-btn>
						<v-btn>今日待备</v-btn>
						<v-btn>准备完成</v-btn>
					</v-btn-toggle>
					<v-divider/>
				</template>
				<template v-slot:item.apntOprtDate="{ item }">
					{{formatTime(item.apntOprtDate)}}
				</template>
				<template v-slot:item.prepareDate="{ item }">
					{{formatTime(item.prepareDate)}}
				</template>
				<template v-slot:item.progress.prepare="{ item }">
					{{formatTime(item.progress.prepare)}}
				</template>
				<template v-slot:item.dob="{ item }">
					{{item.pii.dob}}
				</template>
				<template v-slot:item.age="{ item }">
					{{getAge(item)}}
				</template>
				<template v-slot:item.opts="{ item }">
					{{getOptsStr(item)}}
				</template>
				<template v-slot:item.orderSource="{ item }">
					<span :class="item.orderSource === '定向' ? 'purple--text font-weight-bold' : 'black--text'">{{item.orderSource}}</span>
				</template>
				</v-data-table>
			</div>
			</pane>
			<pane min-size="20" size="65" v-if="(selected.length > 0)">
				<div style="max-height: 100%" class="overflow-y-auto pl-4 pt-4">
					<v-form>
						<div><v-btn rounded color="primary" width="200" class="mt-0 mb-4" @click="save"
							:loading="saving" :disabled="saving||locked">术前准备完成确认</v-btn></div>
					</v-form>
	            </div>
			</pane>
		</splitpanes>
    </div>
    <div v-else>
        无此权限
    </div>
</template>

<script>
    import {formatTime, calculateAge, today} from '../utils'
    import { Splitpanes, Pane } from 'splitpanes'
    import 'splitpanes/dist/splitpanes.css'

    export default {
        data() {
            return {
                authed: false,
                headers: [
                    {text:'姓名', value:'pii.username', width:100},
                    {text:'渠道', value:'orderSource', width:80},
                    {text:'预约手术时间', value:'apntOprtDate', width:180},
                    {text:'开始准备时间', value:'prepareDate', width:180},
                    {text:'准备完成时间', value:'progress.prepare', width:180},
                    {text:'电话', value:'pii.phone', width:100},
                    {text:'套餐内容', value:'opts', width:240, sortable: false},
                    {text:'性别', value:'pii.gender', width:100},
                    {text:'身高', value:'QA.baseinfo.bodyheight', width:100},
                    {text:'体重', value:'QA.baseinfo.bodyweight', width:100},
                    {text:'出生日期', value:'dob', width:120},
                    {text:'年龄', value:'age', width:100},
                    {text:'健康顾问', value:'consultantName', width:150},
                    {text:'诊所', value:'region', width:100},
                ],
                items: [],
                loading: false,
				selected: [],
				pathology: '',
				saving: false,
				locked: false,
				filter: 0,
				options: {},
				totalItems: 0,
            }
        },
        mounted() {
			this.authed = this.$hasPrivilege('健康顾问');
			if (!this.authed) return;
			this.formatTime = formatTime;
			this.fetchData();
        },
        methods: {
			async fetchData() {
				const db = this.$tcbapp.database();
				const _ = db.command;
				let filter = [];
				if (this.$hasPrivilege('admin')) {
					//
				} else if (this.$hasPrivilege('健康顾问')) {
					const u = this.$getCurrentUser();
					filter.push({consultantId: u.consultantId});
				}
				filter.push({hide:_.neq(true)});
				const filters = [
					[{apntOprtDate:_.exists(true)}, {'progress.prepare':_.exists(false)}],
					[{prepareDate:_.gt(today.begin()).lt(today.end())}, {'progress.prepare':_.exists(false)}],
					[{'progress.prepare':_.exists(true)}],
				];
				const f = filter.concat(filters[this.filter]);
				this.loading = true;
				try {
                    const countRes = await db.collection('wp2order').where(_.and(f)).count();
					this.totalItems = countRes.total;
					const { sortBy, sortDesc, page, itemsPerPage } = this.options;
					const res = await db.collection('wp2order').where(_.and(f)).orderBy(sortBy[0]||'_', sortDesc[0] ? 'desc' : 'asc')
						.skip((page-1) * itemsPerPage).limit(itemsPerPage)
						.get();
					this.items = res.data;
				} catch(err) {
					console.error(err);
				}
				this.loading = false;
			},
            getAge(item) {
                return calculateAge(item.pii.dob, item.progress.order);
            },
            getOptsStr(item) {
				return item.opts.GIE + '/' + item.opts.ANES + '/' + item.opts.PRO;
			},
			async save() {
                this.saving = true;
				const id = this.selected[0]._id;
				try {
					await this.$tcbapp.callFunction({name:"wp2mp",
						data:{
							funcname: 'prepare',
							data: { id }
						}
					});
					await this.fetchData();
	                this.locked = true;
				} catch(err) {
					console.error(err);				
				}
				this.saving = false;
			}
		},
		watch: {
			options: {
				handler () {
					this.fetchData()
				},
				deep: true,
			},
			selected(newitem, olditem) {
				if (newitem.length === 0) return;
				this.locked = !!(newitem[0].progress && newitem[0].progress.prepare) || !newitem[0].prepareDate;
			}
		},
        components: { Splitpanes, Pane }
    }
</script>

<style>
.splitpanes.default-theme .splitpanes__pane {
    background-color: white;
}
.splitpanes.default-theme .splitpanes__splitter {
    background-color: #eee;
}
.splitpanes.default-theme .splitpanes__splitter:after,
.splitpanes.default-theme .splitpanes__splitter:before {
    background-color: rgba(0, 0, 0, .25);
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter,
.default-theme .splitpanes--horizontal>.splitpanes__splitter {
    height: 8px;
}
.default-theme.splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:after,
.default-theme.splitpanes--horizontal>.splitpanes__splitter:before,
.default-theme .splitpanes--horizontal>.splitpanes__splitter:before {
    width: 60px;
    height: 1.5px
}
</style>